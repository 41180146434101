@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply antialiased;

  body {
    min-height: 100vh;
    overflow-x: hidden;
    color: #11132e;
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-corner {
    background: #f5f5f5;
  }

  ::-webkit-scrollbar-track {
    background: #f5f5f5;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b7b9bd;
    border-radius: 20px;
    border: 2px #b7b9bd;
  }
}
