.sendbird {
  &-modal {
    z-index: 1000;
  }
}
.sendbird-ag {
  .sendbird-ag-openicon {
    display: none;
  }
  .sendbird-app__settingspanel-wrap {
    width: 320px;
    height: 100%;
    position: absolute;
    right: 0;
    box-shadow: 10px 0 30px #888;
  }
  .sendbird-badge .sendbird-badge__text {
    line-height: 20px;
  }

  .sendbird-channel-header .sendbird-channel-header__title {
    .sendbird-channel-header__title__right {
      display: flex;
      align-items: center;
      padding: 0;
    }
    .sendbird-channel-header__title__right__user-id {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .sendbird-ag {
    .sendbird-ag-openicon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #742cdd66;
      background-color: var(--sendbird-light-primary-100);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      transform: translateX(0);
      transition: ease-in-out 0.3s transform;
    }
    .sendbird-app__wrap {
      width: calc(100% + 320px);
      transform: translateX(-320px);
      transition: ease-in-out 0.3s transform;
    }

    &.open {
      .sendbird-ag-openicon {
        transform: translateX(320px);
        .icon {
          transform: rotate(180deg);
        }
      }
      .sendbird-app__wrap {
        transform: translateX(0);
      }
    }
  }
}
